import { useEffect, useState } from "react";

import { storeEvent } from "_analytics/index";
import {
	useGetInProcessingItem,
	useGetUploadTypeAtom,
	useSetInProcessingItem,
	useSetUploadBtnAtom,
	useSetUploadTypeAtom,
	useSetVideoModal
} from "_common/hooks/uploadVideo";
import { updateUserViewStatus } from "_services/api/upload";

import Presentation from "./presentation";

const updateStatus = async ( { feedId, type } ) => {
	if ( !feedId )
		return;

	const res = await updateUserViewStatus ( { contentType: type, feedId, userViewStatus: true } );

	return res;
};

const component = ( {
	loading,
	isUploadingAsNewVideo
} ) => {

	const setUploadType       = useSetUploadTypeAtom ();
	const uploadType          = useGetUploadTypeAtom ();
	const setUploadBtn        = useSetUploadBtnAtom ();
	const inProcessingItem    = useGetInProcessingItem ();
	const setInProcessingItem = useSetInProcessingItem ();

	const setVideoModal = useSetVideoModal ();

	const uploadStatus = inProcessingItem ?
		Object.values ( inProcessingItem )?.filter ( Boolean )?.[0].status
		: false;

	const showWarningModal = [
		"COMPLETED",
		"TIMEOUT",
		"FAILED"
	].includes ( uploadStatus );

	const [ open, setOpen ] = useState ( showWarningModal );

	const warningModalContentObj = inProcessingItem ?
		inProcessingItem?.[uploadType] || Object.values ( inProcessingItem )?.filter ( Boolean )?.[0] :
		null;

	const currentUploadingItem = inProcessingItem?.[warningModalContentObj?.type];

	useEffect ( () => {
		setOpen ( showWarningModal );
	}, [ showWarningModal ] );

	const closeModal = () => {
		updateStatus ( {
			feedId : inProcessingItem?.[currentUploadingItem?.type]?.feedId,
			type   : inProcessingItem?.[currentUploadingItem?.type]?.type
		} );
		setInProcessingItem ( null );
		setOpen ( false );
	};

	const onReuploadContent = type => {
		storeEvent ( "UGC_creation_started", "Navigated_to", type );
		setVideoModal ( true );
		closeModal ();
		setUploadType ( type );
		setUploadBtn ( type );
	};

	const config = {
		FAILED: {
			error         : true,
			title         : `${ currentUploadingItem?.type } processing failed.`,
			subtitle      : warningModalContentObj?.userMessage,
			primaryAction : {
				label  : "Re-upload",
				action : () => onReuploadContent ( currentUploadingItem?.type )
			}
		},
		COMPLETED: {
			error         : false,
			title         : `${ currentUploadingItem?.type } processing completed.`,
			subtitle      : warningModalContentObj?.userMessage,
			primaryAction : {
				label  : "Okay",
				action : () => closeModal ()
			}
		},
		TIMEOUT: {
			error         : true,
			title         : `${ currentUploadingItem?.type } processing failed.`,
			subtitle      : warningModalContentObj?.userMessage,
			primaryAction : {
				label  : "Re-upload",
				action : () => onReuploadContent ( currentUploadingItem?.type )
			}
		}
	};

	if ( !inProcessingItem )
		return null;

	return (
		<Presentation
			config                = { config?.[ Object?.values?. ( inProcessingItem )?.filter ( Boolean )?.[0]?.status ] }
			isOpen                = { open }
			isUploadingAsNewVideo = { isUploadingAsNewVideo }
			loading               = { loading }
			onClose               = { closeModal }
		/>
	);
};

export default component;