
import Presentation from "./presentation";

const HomeFeed = ( { trendingFeedList, allTabsWithFeedList, gameList, activeTab, reelList, proFeedList, shopData } ) => {

	return (
		<Presentation
			activeTab           = { activeTab }
			allTabsWithFeedList = { allTabsWithFeedList }
			gameList            = { gameList }
			proFeedList         = { proFeedList }
			reelList            = { reelList }
			shopData            = { shopData }
			trendingFeedList    = { trendingFeedList }
		/>
	);
};

export default HomeFeed;