
const openLearnMorePage = router => {
};

const goToEarnPage = router => {
	router.push ( "/creator-dashboard/earning" );
};

const goToMonetisationPage = router => {
	router.push ( "/creator-dashboard/overview" );
};

export const formatMonetisationData = params => {
	const {
		formatType,
		response,
		darkMode,
		router,
		openFeedback
	} = params;

	if ( !response )
		return;

	let modalData = null;

	const notApprovedImg = darkMode ? "/images/creatorDashboardImages/rejected-darkmode.webp" : "/images/creatorDashboardImages/rejected.webp";
	const approvedImg    = darkMode ? "/images/creatorDashboardImages/clap-darkmode.webp" : "/images/creatorDashboardImages/clap.webp";
	const monetisedImg   = darkMode ? "/images/creatorDashboardImages/monetisation-darkmode.webp" : "/images/creatorDashboardImages/monetisation.webp";

	switch ( formatType ) {
		case "live": {

			const {
				status
			} = response;

			const index = status.findIndex ( step => step.showPopUp && step.currentLevel );

			let finalRes = null;

			if ( index === -1 ) {
				modalData = { // this is case of not showing popup
					showPopUp  : false,
					formatType : "live"
				};

				break;
			}

			finalRes = status[index];

			const {
				totalHoursCompleted,
				showPopUp,
				level
				// description
			} = finalRes;

			switch ( level ) {

				case "Step 1": {
					modalData = {
						buttons    : [ { text: "View Journey", type: "contained", onClick: () => goToMonetisationPage ( router ) } ],
						title      : "Congratulations!",
						subTitle   : `You’ve completed ${ totalHoursCompleted } of listening time & gained 100 followers. Profile has been sent for Approval.`,
						img        : approvedImg,
						showPopUp,
						formatType : "live"
					};
					break;
				}

				case "Step 2": {
					modalData = {
						buttons    : [ { text: "View Feedback", type: "contained", onClick: openFeedback } ],
						title      : "We are sorry!",
						subTitle   : "Your Profile has not been Approved.  View Feedback for more information.",
						img        : notApprovedImg,
						showPopUp,
						formatType : "live"
					};
					break;
				}

				case "Step 3": {
					modalData = {
						buttons    : [ { text: "Go to Earnings", type: "contained", onClick: () => goToEarnPage ( router ) }, { text: "More About Monetisation", type: "contained", onClick: () => openLearnMorePage ( router ) } ],
						title      : "HURRAY",
						subTitle   : "Your profile has been qualified for live stream monetisation.",
						img        : monetisedImg,
						showPopUp,
						formatType : "live"
					};
					break;
				}

				default:
					break;
			}

			// profileIncomplete: {
			// buttons  : [ { text: "Complete Profile", type: "contained", onClick: goToProfile } ],
			// title    : "Complete Profile to Register",
			// subTitle : "Complete your profile first before applying for registration.",
			// img      : "/images/creatorDashboardImages/error-alert@3x.webp"
			// }

			break;
		}

		case "reels": {
			if ( Object.keys ( response ).length === 1 ) {
				modalData = {
					// backend sends showpopUp = false here
					showPopup  : response.showpopUp, // this is case of not showing popup
					formatType : "reels"
				};

				break;
			}

			const {
				title,
				subTitle,
				statusText,
				showpopUp
			} = response;

			switch ( statusText ) {
				case "accept": {
					modalData = {
						buttons    : [ { text: "View Journey", type: "contained", onClick: () => goToMonetisationPage ( router ) } ],
						title,
						subTitle,
						img        : approvedImg,
						showPopUp  : showpopUp,
						formatType : "reels"
					};
					break;
				}

				case "reject": {
					modalData = {
						buttons    : [ { text: "View Feedback", type: "contained", onClick: openFeedback } ],
						title,
						subTitle,
						img        : notApprovedImg,
						showPopUp  : showpopUp,
						formatType : "reels"
					};
					break;
				}

				case "complete": {
					modalData = {
						buttons    : [ { text: "Go to Earnings", type: "contained", onClick: () => goToEarnPage ( router ) }, { text: "More About Monetisation", type: "contained", onClick: () => openLearnMorePage ( router ) } ],
						title,
						subTitle,
						img        : monetisedImg,
						showPopUp  : showpopUp,
						formatType : "reels"
					};
					break;
				}

				default:
					break;
			}
			break;

		}

		default:
			break;
	}

	return modalData;

};
