import { getVideoCardCountPerRow } from "_common/utils";
import VideoCardList               from "_components/organisms/videoCardList";
import useStyles                   from "_styles/templates/homepage/videoSection";

import Header from "../header";

const component = ( { rows = 1, title, data = [], onClickViewMore, from } ) => {
	const { classes } = useStyles ( undefined, { name: "homepage/videoSection" } );

	const numberOfCardsPerRow = getVideoCardCountPerRow ();

	const dataCount = numberOfCardsPerRow * rows;

	if ( !data.length ) return null;

	return (
		<div className = { classes.container }>
			<Header onClickViewMore = { onClickViewMore }
				title                  = { title }
			/>

			<VideoCardList
				cardType = { "stream" }
				from     = { from }
				list     = { data.slice ( 0, dataCount ) }
			/>

		</div>
	);
};

export default component;
