import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => ( {
	body: {
		background   : theme.other.colors.dialog.primary,
		borderRadius : "1rem",
		overflow     : "hidden",
		padding      : "2rem",
		border       : `1px solid ${ theme.other.colors.border.primary }`
	},
	root: {
		borderRadius: "1rem"
	},
	closeIcon: {
		height     : "3rem",
		width      : "3rem",
		display    : "flex",
		marginLeft : "auto",
		cursor     : "pointer"
	},
	errorAlertIcon: {
		height       : "5rem",
		width        : "5rem",
		marginBottom : "1.4rem"

	},
	mainBody: {
		textAlign: "center"
	},
	title: {
		fontWeight    : "500",
		textTransform : "capitalize",
		fontSize      : "1.6rem"
	},
	subTitle: {
		fontSize : "1.2rem",
		margin   : "1.6rem 0"
	},
	reuploadBtn: {
		marginBottom: "1.2rem"
	},
	reuploadBtnText: {
		fontWeight: "500"
	},
	newVideoBtn: {
		color  : theme.other.paletteNew.primaryDarkBlue,
		cursor : "pointer"
	}

} ) );
