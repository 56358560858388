import { atom, useAtomValue, useSetAtom } from "jotai";

export const accountTypeAtom = atom ( "" );
export const phoneAtom = atom ( "" );
export const accountNumberAtom = atom ( "" );
export const ifscCodeAtom = atom ( "" );
export const upiIdAtom = atom ( "" );
export const errorAtom = atom ( true );
export const ifscErrorAtom = atom ( true );
export const feedbackModalAtom = atom ( false );
export const paytmModesPayoutAtom = atom ( null );
export const monetisationTotalBalanceAtom = atom ( null );
export const diamondCurrencyConversionAtom = atom ( null );

export const useSetAccountType = () => {
	return useSetAtom ( accountTypeAtom );
};

export const useGetAccountType = () => {
	return useAtomValue ( accountTypeAtom );
};

export const useSetPhone = () => {
	return useSetAtom ( phoneAtom );
};

export const useGetPhone = () => {
	return useAtomValue ( phoneAtom );
};

export const useSetAccountNumber = () => {
	return useSetAtom ( accountNumberAtom );
};

export const useGetAccountNumber = () => {
	return useAtomValue ( accountNumberAtom );
};

export const useSetIfscCode = () => {
	return useSetAtom ( ifscCodeAtom );
};

export const useGetIfscCode = () => {
	return useAtomValue ( ifscCodeAtom );
};

export const useSetUpiId = () => {
	return useSetAtom ( upiIdAtom );
};

export const useGetUpiId = () => {
	return useAtomValue ( upiIdAtom );
};

export const useSetError = () => {
	return useSetAtom ( errorAtom );
};

export const useGetError = () => {
	return useAtomValue ( errorAtom );
};

export const useSetIfscError = () => {
	return useSetAtom ( ifscErrorAtom );
};

export const useGetIfscError = () => {
	return useAtomValue ( ifscErrorAtom );
};

export const useSetFeedbackModal = () => {
	return useSetAtom ( feedbackModalAtom );
};

export const useGetFeedbackModal = () => {
	return useAtomValue ( feedbackModalAtom );
};

export const useSetPaytmModesPayout = () => {
	return useSetAtom ( paytmModesPayoutAtom );
};

export const useGetPaytmModesPayout = () => {
	return useAtomValue ( paytmModesPayoutAtom );
};

export const useSetMonetisationTotalBalance = () => {
	return useSetAtom ( monetisationTotalBalanceAtom );
};

export const useGetMonetisationTotalBalance = () => {
	return useAtomValue ( monetisationTotalBalanceAtom );
};

export const useSetDiamondCurrencyConversion = () => {
	return useSetAtom ( diamondCurrencyConversionAtom );
};

export const useGetDiamondCurrencyConversion = () => {
	return useAtomValue ( diamondCurrencyConversionAtom );
};