import { useRouter }                  from "next/router";
import React, { useEffect, useState } from "react";

import { useGetProfileId } from "_common/hooks/auth";
import { useGetDarkMode }  from "_common/hooks/global";
import {
	getBroadcastMonetisationStatus,
	setMonetizationPopup
} from "_services/api/broadcast";
import { updateReelVideoModalState, getVideoAndReelMonetisationStatus } from "_services/api/creator-dashboard";

import Presentation               from "./presentation";
import { formatMonetisationData } from "./utils";

const updateModalStatus = async type => {

	let callApi = type === "live" ? setMonetizationPopup : updateReelVideoModalState;

	try {
		await callApi (); // post
	} catch ( e ) {
		return;
	}
};

const getApiResponse = async type => {

	let callApi = type === "live" ? getBroadcastMonetisationStatus : getVideoAndReelMonetisationStatus;

	try {
		const response = await callApi ();

		return response;
	} catch ( e ) {
		return;
	}
};

const component = props => {
	const {
		setShowFeedback
	} = props;

	const [ modalState, setModalState ] = useState ( false );
	const [ modalData, setModalData ]   = useState ( null );
	const darkMode                      = useGetDarkMode ();
	const router                        = useRouter ();
	const userId                        = useGetProfileId ();

	useEffect ( () => {
		if ( !userId )
			return;
		handleMonetisationData ();
	}, [ userId ] );

	const closeModal = () => {
		const {
			formatType
		} = modalData;

		updateModalStatus ( formatType );
		setModalState ( false );
	};

	const openFeedback = () => {
		closeModal ();
		setShowFeedback ( true );
	};

	const closeFeedBack = () => {
		setShowFeedback ( false );
	};

	const handleMonetisationData = async () => {

		// return null;
		const reelsRes = await getApiResponse ( "reels" ); /* for Reels And Video */
		const liveRes  = await getApiResponse ( "live" ); /* for Live */

		const reelAndVideoData = formatMonetisationData ( { formatType: "reels", response: reelsRes, darkMode, router, openFeedback } );
		const liveStreamData   = formatMonetisationData ( { formatType: "live", response: liveRes, darkMode, router, openFeedback } );

		if ( reelAndVideoData?.showPopUp && liveStreamData?.showPopUp ) {
			setModalState ( liveStreamData.showPopUp );
			setModalData ( liveStreamData );

			return;
		} // return one of the two anyway

		if ( reelAndVideoData?.showPopUp ) {
			setModalData ( reelAndVideoData ); // reel monetisation data
			setModalState ( reelAndVideoData.showPopUp );

			return;
		}

		if ( !reelAndVideoData || !liveStreamData )
			return;

		setModalState ( liveStreamData.showPopUp );
		setModalData ( liveStreamData );

		return;// if its not reel then its livestream

	};

	if ( !modalData || !modalState )
		return null;

	return (
		<Presentation
			closeFeedBack = { closeFeedBack }
			closeModal    = { closeModal }
			modalData     = { modalData }
			modalState    = { modalState }
		/>
	);
};

export default component;