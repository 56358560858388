import { useEffect, useState } from "react";

import { useGetProfileId }                          from "_common/hooks/auth";
import { useGetFeedbackModal, useSetFeedbackModal } from "_common/hooks/creator-dashboard/earn";
import { getCreatorDashboardStatus }                from "_services/api/creator-dashboard";

import Presentation from "./presentation";

const HomePageModal = () => {

	const showFeedback          = useGetFeedbackModal ();
	const setShowFeedback       = useSetFeedbackModal ();
	const [ status, setStatus ] = useState ( false );
	const userId                = useGetProfileId ();

	useEffect ( () => {
		if ( !userId )
			return;
		_getCreatorDashboardStatus ();
	}, [ userId ] );

	const _getCreatorDashboardStatus = async () => {
		try {
			const { creatorDashboardStatus, isViewed } = await getCreatorDashboardStatus ();

			setStatus ( creatorDashboardStatus === 1 && isViewed === 0 );

		} catch ( e ) {
			return;
		}
	};

	return (
		<Presentation
			setShowFeedback = { setShowFeedback }
			showFeedback    = { showFeedback }
			status          = { status }
		/>
	);
};

export default HomePageModal;