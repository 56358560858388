import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => {
	return {
		modalRoot: {
		},
		modalContent: {
			flexBasis    : "min(600px, 90%)",
			borderRadius : "1.8rem"
		},
		modalBody: {
			borderRadius  : "1rem",
			display       : "flex",
			alignItems    : "center",
			gap           : "2rem",
			flexDirection : "column",
			padding       : "0 !important"
		},
		background: {
			borderRadius: "inherit !important"
		},
		feedbackBtn: {
			fontWeight : "500",
			fontSize   : theme.other.isMobile ? "1.7rem" : "2rem"
		},
		headerDiv: {
			padding        : "1.5rem 2rem",
			borderBottom   : theme.other.darkMode ? `solid 1px ${ theme.other.paletteNew.darkModeWhite20 }` : `solid 1px ${ theme.other.colors.border.primary }`,
			background     : theme.other.darkMode ? theme.other.paletteNew.darkModeInclinedBlack1 : theme.other.paletteNew.secondaryWhite,
			display        : "flex",
			alignItems     : "center",
			width          : "100%",
			justifyContent : "space-between"
		},
		contentDiv: {
			display         : "flex",
			flexDirection   : "column",
			gap             : "1rem",
			margin          : "2rem",
			backgroundColor : theme.other.darkMode ? theme.other.paletteNew.darkModeInclinedBlack2 : theme.other.paletteNew.secondaryWhite
		},
		feedbackList: {
			listStyleType : "number",
			paddingLeft   : "4rem",
			margin        : "0"
		},
		feedbackListItem: {
			fontWeight  : "500",
			lineHeight  : "4.5",
			"&::marker" : {
				color    : theme.other.darkMode ? theme.other.paletteNew.secondaryWhite : theme.other.paletteNew.secondaryBlack,
				fontSize : theme.other.isMobile ? "1.3rem" : "1.6rem"
			}
		},
		feedbackHeading: {
			color    : theme.other.paletteNew.secondaryDarkGrey,
			fontSize : theme.other.isMobile ? "1.3rem" : "1.6rem"
		},
		feedbackSubHeading: {
			fontWeight : "500",
			fontSize   : theme.other.isMobile ? "1.3rem" : "1.6rem"
		},
		feedbackListHeading: {
			lineHeight : "2.5",
			fontWeight : "inherit",
			fontSize   : theme.other.isMobile ? "1.3rem" : "1.6rem"
		},
		feedbackListText: {
			color      : theme.other.paletteNew.secondaryDarkGrey,
			fontSize   : theme.other.isMobile ? "1.3rem" : "1.6rem",
			lineHeight : "2",
			fontWeight : "normal"

		},
		footerDiv: {
			padding        : "1.5rem 2rem",
			width          : "100%",
			display        : "flex",
			borderTop      : theme.other.darkMode ? `solid 1px ${ theme.other.paletteNew.darkModeWhite20 }` : `solid 1px ${ theme.other.colors.border.primary }`,
			justifyContent : "end",
			background     : theme.other.darkMode ? theme.other.paletteNew.darkModeInclinedBlack1 : theme.other.paletteNew.secondaryWhite
		},
		exampleProfileDiv: {
			display    : "flex",
			alignItems : "center",
			gap        : "1rem",
			flexWrap   : "wrap"
		},
		exampleProfile: {
			display      : "flex",
			alignItems   : "center",
			borderRadius : "1rem",
			padding      : "1rem",
			border       : `1px solid ${ theme.other.paletteNew.secondaryLightGrey }`,
			gap          : "1rem",
			width        : "fit-content"
		},
		exampleProfileHeading: {
			fontWeight : "500",
			alignSelf  : "self-start"
		},
		exampleProfileImg: {
			width        : "25px",
			borderRadius : "50%",
			aspectRatio  : "1/1"
		},
		cancelBtn: {
			// color: theme.other.paletteNew.primaryDarkBlue
		}
	};
} );