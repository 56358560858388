import { useRouter } from "next/router";
import { Fragment }  from "react";

import { convertToSlug }    from "_common/utils";
import { getShopLink }      from "_common/utils/shop";
import UploadStatusModal    from "_components/organisms/uploadVideo/components/uploadStatusModal";
import VideoCardList        from "_components/organisms/videoCardList/container";
import useStyles            from "_components/organisms/videoCardList/styles";
import FeedSection          from "_components/templates/homepage/feedSection";
import GameSection          from "_components/templates/homepage/gameSection";
import Header               from "_components/templates/homepage/header";
import ShopProductsSections from "_components/templates/homepage/shopProductsSections";

import MonetisationModals from "./homePageMonetisationModals";

const component = ( {
	trendingFeedList = [],
	allTabsWithFeedList,
	gameList,
	from,
	activeTab,
	reelList,
	proFeedList,
	shopData,
	loading
} ) => {
	const { classes } = useStyles ( { activeTab } );
	const router      = useRouter ();

	const onClickViewMore = path => {
		switch ( path ) {
			case "reels":
				router.push ( "/reels" );

				break;

			case "shop":
				{
					const url = getShopLink ( { source: "home_page", medium: "web", campaign: "view_more" } );

					window.open ( url, "_blank" );
				}

				break;

			default:
				break;
		}
	};

	return (
		<>
			{
				proFeedList?.feedList?.length ? (
					<FeedSection
						data       = { proFeedList.feedList }
						feedTypeId = { proFeedList.feedTypeId }
						rows       = { 2 }
						slug       = { convertToSlug ( proFeedList.display_name ) }
						title      = { proFeedList.display_name }
					/>
				) : null
			}

			{

				<div className = { classes.reelContainer }>
					<Header
						onClickViewMore = { () => onClickViewMore ( "shop" ) }
						title           = { "Rooter Shop" }
					/>

					<ShopProductsSections
						loading  = { loading }
						shopData = { shopData }
					/>
				</div>
			}

			{
				trendingFeedList?.feedList?.length ? (
					<FeedSection
						data  = { trendingFeedList.feedList }
						rows  = { 2 }
						slug  = { convertToSlug ( trendingFeedList.display_name ) }
						title = { trendingFeedList.display_name }
					/>
				) : null
			}

			{
				// reel section

				reelList?.length ? (

					<div className = { classes.reelContainer }>
						<Header
							onClickViewMore = { () => onClickViewMore ( "reels" ) }
							title           = { "Reels" }
						/>

						<VideoCardList
							cardType = { "reel" }
							from     = { from }
							list     = { reelList }
						/>

					</div>
				) : null
			}

			{
				gameList?.length ? (
					<GameSection
						gameList = { gameList }
						title    = { "Recommended Categories" }
					/>
				)
					: null
			}

			{
				allTabsWithFeedList.map ( ( tab, idx ) => {
					return (
						<Fragment key = { idx }>
							<FeedSection
								activeTab  = { activeTab }
								data       = { tab?.feedList || [] }
								feedTypeId = { tab?.feedTypeId }
								from       = { from }
								slug       = { convertToSlug ( tab.display_name ) }
								title      = { tab.display_name }
							/>
						</Fragment>
					);
				} )
			}

			<MonetisationModals />

			<UploadStatusModal />
		</>
	);

};

export default component;
