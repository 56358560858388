import { useRouter } from "next/router";

import Presentation from "./presentation";

export const VideoSection = ( { rows, title, slug, data, feedTypeId = -1, from } ) => {
	const router = useRouter ();

	const onClickViewMore = () => {
		router.push ( `/feed/${ feedTypeId }?type=${ slug }` );

	};

	return (
		<Presentation
			data            = { data }
			from            = { from }
			onClickViewMore = { onClickViewMore }
			rows            = { rows }
			slug            = { slug }
			title           = { title }
		/>
	);
};

export default VideoSection;