import StatusModal       from "_components/features/creatorDashboard/organisms/dataMigrationModal";
import FeedbackModal     from "_components/features/creatorDashboard/organisms/feedbackModal/presentation";
import MonetisationModal from "_components/features/creatorDashboard/organisms/notificationModal";

import useStyles from "./styles";

const component = props => {
	const {
		showFeedback,
		setShowFeedback,
		status
	} = props;

	return (
		<>
			<MonetisationModal setShowFeedback = { setShowFeedback } />

			<FeedbackModal
				skipModalStatusCall
				modal          = { showFeedback }
				toggleFeedback = { () => setShowFeedback ( false ) }
				type           = { "" }
			/>

			<StatusModal isHomePageNotification
				isMigratingData = { status }
			/>
		</>
	);
};

export default component;
