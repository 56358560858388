import { Modal }      from "@mantine/core";
import { ActionIcon } from "@mantine/core";

import BackgroundWrapper from "_components/atoms/backgroundWrapper";
import Button            from "_components/atoms/button";
import { TextButton }    from "_components/atoms/button";
import Icon              from "_components/atoms/icon";
import Image             from "_components/atoms/image";
import Typography        from "_components/atoms/typography";

import useStyles from "./styles";

const NotificationModal = props => {
	const {
		modalState,
		closeModal,
		modalData
	} = props;
	const { classes } = useStyles ( undefined, { name: "styles" } );

	const { title, img, subTitle, buttons } = modalData;

	return (
		<Modal
			centered
			className       = { classes.modalRoot }
			onClose         = { closeModal }
			opened          = { modalState }
			size            = { "lg" }
			trapFocus       = { false }
			withCloseButton = { false }
			zIndex          = { 1001 }
		>
			<BackgroundWrapper className = { classes.background }>
				<ActionIcon className = { classes.svgIcon }>
					<Icon
						name    = "cross"
						onClick = { closeModal }
					/>
				</ActionIcon>

				<Image className = { classes.modalImage }
					placeholder     = "/images/carousel.webp"
					src             = { img }
				/>

				<Typography className = { classes.modalHeading }
					title                = { title }
				/>

				<Typography className = { classes.modalSubheading }
					title                = { subTitle }
				/>

				<div className = { classes.buttonDiv }>
					{
						buttons?.map ( ( button, index ) => {
							if ( button.type === "contained" )
								return (
									<Button key   = { `${ button.text } ${ index }` }
										className    = { `modalBtn ${ classes[button.type] }` }
										color        = "secondary"
										data-primary = { true }
										onClick      = { button?.onClick }
										variant      = { button?.type }
									>
										<Typography className = { classes.btnText }
											title                = { button.text }
										/>
									</Button>
								);

							return (
								<TextButton
									key                 = { `${ button.text } ${ index }` }
									buttonTextClassName = { classes.cancelText }
									className           = { classes.cancelButton }
									label               = { button.text }
									onClick             = { button?.onClick }
									size                = "small"
								/>
							);
						} )
					}
				</div>

			</BackgroundWrapper>
		</Modal>
	);
};

export default NotificationModal;
