import { createStyles } from "@mantine/emotion";

export default createStyles ( ( theme, _, u ) => ( {
	container: {
		margin: "4rem 2rem"
	},
	header: {
		display      : "flex",
		alignItems   : "center",
		marginBottom : "1rem"
	},
	title: {
		fontSize   : "1.75rem",
		fontWeight : "500"
	},
	icon: {
		height     : "1.5rem",
		width      : "1.5rem",
		marginLeft : "1rem"
	},
	videoCardContainer: {
		display             : "grid",
		gridTemplateColumns : "repeat(3, minmax(0, 1fr ))",
		gridGap             : "3rem 1.5rem",

		[u.largerThan ( "xs" )]: {
			gridTemplateColumns: "repeat(1, minmax(0, 1fr ))"
		},
		[u.largerThan ( "sm" )]: {
			gridTemplateColumns: "repeat(2, minmax(0, 1fr ))"
		},

		[u.largerThan ( "md" )]: {
			gridTemplateColumns: "repeat(3, minmax(0, 1fr ))"
		},

		[u.largerThan ( "lg" )]: {
			gridTemplateColumns: "repeat(4, minmax(0, 1fr ))"
		},

		[u.largerThan ( "xl" )]: {
			gridTemplateColumns: "repeat(5, minmax(0, 1fr ))"
		},

		[u.largerThan ( "xxxl" )]: {
			gridTemplateColumns: "repeat(6, minmax(0, 1fr ))"
		}

	}
} ) );
