import { Modal } from "@mantine/core";

import BackgroundWrapper from "_components/atoms/backgroundWrapper";
import BaseButton        from "_components/atoms/button/baseButton";
import Typography        from "_components/atoms/typography";

import useStyles from "./styles";

const FeedbackModal = props => {
	const {
		updateModalStatus,
		toggleFeedback,
		modal,
		skipModalStatusCall
	} = props;
	const { classes } = useStyles ( undefined, { name: "styles" } );

	const feedbackData = [
		{
			heading : "Try to stream in Better Quality",
			text    : "We feel that the video quality of your stream is not up to our desired level."
		},
		{
			heading : "Use Thumbnails & Overlays",
			text    : "You haven’t used Thumbnails and Overlays in your streams consistently. For giving best experience to our viewers, we would recommend you to use the same."
		}
	];

	if ( !modal )
		return null;

	return (
		<Modal
			centered
			classNames      = { {
				root    : classes.modalRoot,
				body    : classes.modalBody,
				content : classes.modalContent
			} }
			onClose         = { () => {
				if ( !skipModalStatusCall )
					updateModalStatus ();
				toggleFeedback ();
			} }
			opened          = { modal }
			size            = { 1200 }
			trapFocus       = { false }
			withCloseButton = { false }
			zIndex          = { 1000 }
		>

			<BackgroundWrapper className = { classes.background }>
				<div className = { classes.headerDiv }>
					<Typography
						className = { classes.feedbackBtn }
						title     = "Feedback"
					/>

					<BaseButton
						buttonTextClassName = { classes.cancelBtn }
						title               = "Contact Us"
						variant             = "filled"
					/>
				</div>

				<div className = { classes.contentDiv }>
					<div className = { classes.feedbackHeading }>

						<Typography
							color     = { "secondary" }
							component = { "span" }
							title     = { "First of all, don’t feel bad. We will" }
						/>

						<Typography
							className = { classes.feedbackSubHeading }
							component = { "span" }
							title     = { " review your profile again in 21 days. " }
						/>

						<Typography
							color     = { "secondary" }
							component = { "span" }
							title     = { "Till then work on your following:" }
						/>
					</div>

					<ul className = { classes.feedbackList }>
						{
							feedbackData.map ( ( val, index ) => {

								return (
									<li key    = { index }
										className = { classes.feedbackListItem }
									>
										<Typography
											className = { classes.feedbackListHeading }
											title     = { val.heading }
										/>

										<Typography
											className = { classes.feedbackListText }
											title     = { val.text }
										/>

									</li>
								);
							} )
						}

					</ul>

					{/* <Typography className = { classes.exampleProfileHeading }
						title = "Top Profile Examples"
					/>

					<div className = { classes.exampleProfileDiv }>

						{
							[ 1, 2 ].map ( ( val, index ) => {
								return (
									<div key = { index }
										className = { classes.exampleProfile }
									>
										<Image className = { classes.exampleProfileImg }
											src = "/images/carousel.webp"
										/>

										<Typography
											title = "Best Profile"
										/>

										{index === 0 && <LiveTag />}
									</div>
								);
							} )
						}
					</div> */}

				</div>

				<div className = { classes.footerDiv }>
					<BaseButton
						buttonTextClassName = { classes.cancelBtn }
						className           = { classes.cancelBtn }
						onClick             = { () => {
							if ( !skipModalStatusCall )
								updateModalStatus ();
							toggleFeedback ();
						} }
						title               = "Cancel"
						variant             = "filled"
					/>
				</div>
			</BackgroundWrapper>
		</Modal>

	);
};

export default FeedbackModal;
