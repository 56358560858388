import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => {
	return {
		modalRoot: {
			"& .mantine-Modal-content": {
				borderRadius : "2rem",
				background   : "none !important"
			},
			"& .mantine-Modal-modal": {
				borderRadius : "1.5rem",
				padding      : "0"
			},
			"& .mantine-Modal-body": {
				borderRadius : "inherit",
				padding      : "0"
			},
			"& .mantine-Modal-header": {
				margin: 0
			}
			// "& .mantine-Modal-close": {
			// color     : theme.other.darkMode ? "white" : "black",
			// transform : "scale( 2 )",
			// position  : "absolute",
			// right     : "2rem",
			// top       : "2rem",
			// "&:hover" : {
			// background: "gray"
			// }
			// }
		},
		background: {
			borderRadius  : "inherit",
			display       : "flex",
			alignItems    : "center",
			flexDirection : "column",
			gap           : "2rem",
			padding       : "2rem",
			position      : "relative"
		},
		modalImage: {
			width        : "40%",
			aspectRatio  : "1/1",
			borderRadius : "50%"
		},
		modalHeading: {
			fontSize   : "2rem",
			fontWeight : "bolder"
		},
		modalSubheading: {
			color     : theme.other.colors.text.secondary,
			fontSize  : "1.3rem",
			textAlign : "center",
			width     : "100%"
		},
		buttonDiv: {
			display        : "flex",
			gap            : "1rem",
			flexDirection  : "column",
			width          : "100%",
			justifyContent : "center",
			"& .modalBtn"  : {
				cursor: "pointer"
			}
		},
		contained: {
			background : theme.other.colors.primaryBtn.default.background,
			boxShadow  : "none",
			padding    : "1rem",
			"&:hover"  : {
				boxShadow: "none"
			},
			width        : "inherit",
			borderRadius : "10px"
		},
		filled: {
			color        : theme.other.colors.secondaryBtn.clicked.text,
			borderRadius : "10px"
		},
		btnText: {
			color      : "inherit",
			fontWeight : "500"
		},
		svgIcon: {
			position  : "absolute",
			top       : "5%",
			right     : "5%",
			width     : "2.5rem",
			cursor    : "pointer",
			"&:hover" : {
				background: "none !important"
			}
		},
		cancelButton: {
			color: theme.other.paletteNew.primaryDarkBlue
		},
		cancelText: {
			fontWeight : "500",
			fontSize   : "1.5rem"
		}
	};
} );